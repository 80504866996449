import { initContract } from '@ts-rest/core';
import {
   createTimetapAppointmentSchema,
   updateAppointmentTimetapSchema,
   intExtendedField,
   welkinListEncountersParamsSchema,
   uuidSchema,
   generateMeetingUrlParamsSchema,
   AppointmentItem,
   TimetapAppointmentResponseDto,
   AppointmentWithTimetapItem,
   GenerateMeetingUrlResponseDto,
   CreateTimetapResponseDto,
} from '@innerwell/dtos';
import { z } from 'zod';

const c = initContract();
export const appointments = c.router(
   {
      createTimetap: {
         method: 'POST',
         path: '/appointments/timetap',
         body: createTimetapAppointmentSchema,
         responses: {
            200: c.type<CreateTimetapResponseDto>(),
         },
      },
      updateTimetap: {
         method: 'PATCH',
         path: '/appointments/timetap/:id',
         pathParams: z.object({
            id: intExtendedField,
         }),
         body: updateAppointmentTimetapSchema,
         responses: {
            200: c.type<TimetapAppointmentResponseDto>(),
         },
      },
      list: {
         method: 'GET',
         path: '/appointments',
         query: welkinListEncountersParamsSchema,
         responses: {
            200: c.type<AppointmentItem[]>(),
         },
      },
      get: {
         method: 'GET',
         path: '/appointments/:id',
         pathParams: z.object({
            id: uuidSchema,
         }),
         responses: {
            200: c.type<AppointmentItem>(),
         },
      },
      getWithTimetap: {
         method: 'GET',
         path: '/appointments/:id/with-timetap',
         pathParams: z.object({
            id: uuidSchema,
         }),
         responses: {
            200: c.type<AppointmentWithTimetapItem>(),
         },
      },
      generateZoomUrls: {
         method: 'POST',
         path: '/appointments/generate-start-meeting-url',
         body: generateMeetingUrlParamsSchema,
         responses: {
            200: c.type<GenerateMeetingUrlResponseDto>(),
         },
      },
      getJoinUrl: {
         method: 'GET',
         path: '/appointments/:id/join-url',
         pathParams: z.object({
            id: z.string(),
         }),
         responses: {
            200: c.type<string | null>(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
