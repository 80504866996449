import { initContract } from '@ts-rest/core';
import {
   AdsCounterResponseDto,
   DosageConfirmationDto,
   adsUpdateParamsSchema,
   dosageConfirmationSchema,
} from '@innerwell/dtos';

const c = initContract();
export const adsCounter = c.router(
   {
      getCounterInfo: {
         method: 'GET',
         path: '/ads-counter',
         responses: {
            200: c.type<AdsCounterResponseDto>(),
         },
      },
      getDosageConfirmations: {
         method: 'GET',
         path: '/ads-counter/dosage-confirmations',
         query: dosageConfirmationSchema,
         responses: {
            200: c.type<DosageConfirmationDto[]>(),
         },
      },
      addAdsValue: {
         method: 'POST',
         path: '/ads-counter',
         body: adsUpdateParamsSchema,
         responses: {
            200: c.type<AdsCounterResponseDto>(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
