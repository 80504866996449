import 'reflect-metadata';

import _ from 'lodash';
import {
   EncounterDispositionCodes,
   FormsortAnswersResponse,
   FormsortFormTypes,
   PaymentCode,
   Timeslot,
   TimetapLocationResponseDto,
   TimetapLocationType,
   TimetapTimeSlot,
   WelkinEncounterStatus,
   WelkinPrograms,
   isClaimMdExceptionPayload,
} from '@innerwell/dtos';
import { DateTime, Duration } from 'luxon';
import axios from 'axios';
import { isGeneralPsychiatryProgram, isTherapyProgram } from './phase-utils';

export function formatCurrency(value: number, prefix = '', decimals = 2) {
   return `${prefix}${value.toFixed(decimals)}`;
}

export function getPlanNameFromWelkinProgram(program: WelkinPrograms) {
   if (program === WelkinPrograms.Foundation) {
      return 'Foundation Plan';
   }

   if (program === WelkinPrograms.Extended) {
      return 'Extended Plan';
   }

   if (program === WelkinPrograms.FollowOn) {
      return 'Follow-On Plan';
   }

   if (program === WelkinPrograms.FoundationAndTherapy) {
      return 'Foundation + Therapy Plan';
   }

   if (program === WelkinPrograms.FreeRange) {
      return 'Free Range Plan';
   }

   if (isGeneralPsychiatryProgram(program)) {
      return 'General Psychiatry Plan';
   }

   if (program === WelkinPrograms.Intake) {
      return 'Intake Plan';
   }

   if (isTherapyProgram(program)) {
      return 'Therapy Plan';
   }

   if (program === WelkinPrograms.TwoDoses) {
      return 'Two Doses Plan';
   }

   return 'Unknown';
}

export function getDiscountPercentage(
   originalPrice: number,
   discountPrice: number,
) {
   const percentage = ((originalPrice - discountPrice) / originalPrice) * 100;
   return Math.round(percentage * 100) / 100;
}

export function getFinalPrice(
   originalPrice: number,
   discountAmount?: number,
   additionalCartDiscountAmount?: number,
   decimals: 0 | 1 | 2 = 1,
) {
   if (!discountAmount && !additionalCartDiscountAmount) return originalPrice;
   if (discountAmount === 0 && additionalCartDiscountAmount === 0)
      return originalPrice;

   let discountedPrice = originalPrice;
   if (discountAmount) {
      discountedPrice = originalPrice - discountAmount;
   }

   if (additionalCartDiscountAmount) {
      discountedPrice = discountedPrice - additionalCartDiscountAmount;
   }

   if (decimals === 0) {
      return Math.ceil(discountedPrice);
   }

   return (
      Math.ceil(discountedPrice * (decimals === 1 ? 10 : 100)) /
      (decimals === 1 ? 10 : 100)
   );
}

export function normalizePhoneNumber(phoneNumber: string, extension: string) {
   return `${extension}${phoneNumber.replace(/[()]|-|\+1|\s/gi, '')}`;
}

// Converts ugly phone numbers to a nicer format to show on UI
export function prettifyPhoneNumber(phoneNumber?: string) {
   if (!phoneNumber) {
      return '';
   }

   const digits = phoneNumber.replace(/[^\d]/g, '');

   if (digits.length == 10) {
      return digits.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
   }

   return phoneNumber;
}

export function formatDateForNmiApi(date: Date) {
   return DateTime.fromJSDate(date).toFormat('yyyyMMdd');
}

export function parseDateTimeFromNmi(date: string) {
   return DateTime.fromFormat(date, 'yyyyMMddHHmmss', {
      zone: 'utc',
   });
}

// Returns Sunday as the first date
export function getWeekStart(date: DateTime) {
   const offset = Duration.fromObject({ days: 1 });
   return date.plus(offset).startOf('week').minus(offset);
}

/**
 * This converts TZ date into the same **DATE** in UTC. Time is set to 00:00:00.
 *
 * 2022-10-01T13:00:56.000+04:00 becomes
 * 2022-10-01T0:00:00.000Z
 *
 */
export function getUtcDateFromTz(tzDate: DateTime) {
   // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
   return DateTime.fromISO(tzDate.toISODate()!, {
      zone: 'utc',
   });
}

// luxon formatting, refer to: https://github.com/moment/luxon/blob/master/docs/formatting.md
export const APPOINTMENT_DATE_FORMAT = 'ccc, DD';
export const APPOINTMENT_TIME_FORMAT = 't ZZZZ';
export const APPOINTMENT_DATETIME_FORMAT = `${APPOINTMENT_DATE_FORMAT}, ${APPOINTMENT_TIME_FORMAT}`;

export function formatAppointmentDate(date: DateTime) {
   return date.toFormat(APPOINTMENT_DATE_FORMAT);
}

export function formatAppointmentDateTime(date: DateTime) {
   return date.toFormat(APPOINTMENT_DATETIME_FORMAT);
}

export function formatAppointmentTime(date: DateTime) {
   return date.toFormat(APPOINTMENT_TIME_FORMAT);
}

export function formatAdminDateTime(dateOrString: DateTime | string) {
   const date =
      typeof dateOrString === 'string'
         ? DateTime.fromISO(dateOrString)
         : dateOrString;

   return date.toLocaleString(DateTime.DATETIME_FULL);
}

export function getAppointmentPath(meetingId: string, includePassword = false) {
   const params = new URLSearchParams();
   if (includePassword) {
      params.set('pw', 'innerwell');
   }
   return `/meeting/${meetingId}?${params.toString()}`;
}

export function getAppointmentReschedulePath(appointmentId: string) {
   return `/reschedule-appointment?id=${appointmentId}` as const;
}

export function getGoogleMapsDirectionsUrl(address: string) {
   return `https://www.google.com/maps/dir/${address}` as const;
}

export function getAppointmentUrl(meetingId: string, websiteUrl: string) {
   return new URL(getAppointmentPath(meetingId), websiteUrl);
}

export function getAppointmentRescheduleUrl(
   appointmentId: string,
   websiteUrl: string,
) {
   return new URL(getAppointmentReschedulePath(appointmentId), websiteUrl);
}

export function getAppointmentStartUrl(
   patientId: string,
   encounterId: string,
   websiteUrl: string,
) {
   return new URL(
      `/meeting/start?patientId=${patientId}&encounterId=${encounterId}`,
      websiteUrl,
   );
}

export function getAppointmentUrlForClinician({
   patientId,
   encounterId,
   welkinTenant,
   welkinEnv,
}: {
   patientId: string;
   encounterId: string;
   welkinTenant: string;
   welkinEnv: string;
}) {
   return `https://care.live.welkincloud.io/${welkinTenant}/${welkinEnv}/patient/${patientId}/__encounters__/${encounterId}`;
}

// Format x.xx (two decimal places)
export function parseNmiAmount(amount: string) {
   const value = parseFloat(amount);
   if (Number.isNaN(value)) {
      throw new Error(`Cannot parse ${amount}`);
   }

   return value;
}

export function getTimeUnitsFromPaymentFrequency(
   frequency: PaymentCode,
   options: {
      plural?: boolean;
      returnValueOnError?: string;
   } = {
      plural: true,
   },
): string {
   const { plural, returnValueOnError } = options;
   const suffix = plural ? 's' : '';

   switch (frequency) {
      case PaymentCode.OnceInFull:
         return 'in-full';
      case PaymentCode.Monthly:
      case PaymentCode.TwoMonths:
      case PaymentCode.FourMonths:
         return `month${suffix}`;
      case PaymentCode.Weekly:
         return `week${suffix}`;
      case PaymentCode.Infinite: // @TODO: figure this out
      case PaymentCode.Free:
      case PaymentCode.Unknown:
         if (_.isString(returnValueOnError)) {
            return returnValueOnError;
         }
   }

   throw new Error(`Cannot return time units for ${frequency} frequency`);
}

/**
 *
 * Returns number of payments for a given payment method code.
 * Special values:
 * 0: means infinite subscription (until cancelled)
 * -1: no payments (free payment method)
 *
 * @param code
 * @param magentoInstallments Number of installments that magento returns from our custom endpoint
 *    /human-core/guest-carts/:id/payment-methods. Maybe we'll remove it in the future, but if not specified, the fallback
 *    logic should work properly.
 * @returns
 */
export function getNumberOfPaymentsForPaymentCode(
   code: PaymentCode,
   magentoInstallments?: string | number | null,
) {
   const installments = _.isString(magentoInstallments)
      ? parseInt(magentoInstallments)
      : _.isNumber(magentoInstallments)
        ? magentoInstallments
        : Number.NaN;

   if (!Number.isNaN(installments)) {
      return installments;
   }

   if (code === PaymentCode.OnceInFull) {
      return 1;
   }
   if (code === PaymentCode.TwoMonths) {
      return 2;
   }
   if (code === PaymentCode.Monthly) {
      return 3;
   }
   if (code === PaymentCode.FourMonths) {
      return 4;
   }
   if (code === PaymentCode.Weekly) {
      return 13;
   }
   if (code === PaymentCode.Infinite) {
      // for NMI, 0 means infinite number of payments (subscription until cancelled)
      return 0;
   }
   if (code === PaymentCode.Free) {
      return -1;
   }

   throw new Error(
      `Cannot determine number of payments ${code}, installments=${installments}`,
   );
}

export function getInstallmentAmount({
   numOfPayments,
   amount,
}: {
   numOfPayments: number;
   amount: number;
}) {
   // Free product has -1 in num of payments by design
   if (numOfPayments === -1) {
      return 0;
   }

   return numOfPayments > 0 // cover both 0 (infinite) & -1 (free)
      ? amount / numOfPayments
      : // @TODO: define this properly when/if we use infinite payments
        amount;
}

export function getPaymentDurationAdverb(code: PaymentCode) {
   switch (code) {
      case PaymentCode.OnceInFull:
         return 'once';
      case PaymentCode.Monthly:
      case PaymentCode.TwoMonths:
      case PaymentCode.FourMonths:
         return 'monthly';
      case PaymentCode.Weekly:
         return 'weekly';
      case PaymentCode.Infinite: // @TODO: figure this out
      case PaymentCode.Unknown:
   }
   return 'unknown';
}

/**
 * Function defaults to undefined if freq and/or numberOfPayments combo is not processable
 * @param freq
 * @param numberOfPayments
 * @returns
 */
export function getDefaultSubscriptionDurationTitle(
   freq: PaymentCode,
   numberOfPayments: number,
) {
   if (numberOfPayments === 0) {
      return undefined;
   }

   if (numberOfPayments === 1) {
      return 'Paid in Full';
   }

   if ([PaymentCode.Weekly, PaymentCode.Monthly].includes(freq)) {
      return `${numberOfPayments} ${freq.slice(0, -2)}`;
   }

   return undefined;
}

export function isInstallmentPaymentMethod(code: PaymentCode) {
   return [
      PaymentCode.Monthly,
      PaymentCode.Weekly,
      PaymentCode.TwoMonths,
      PaymentCode.FourMonths,
   ].includes(code);
}

// Returns first over-time payment method from the list (if any)
export function getInstallmentPaymentCode(codes: PaymentCode[]) {
   return codes.find(isInstallmentPaymentMethod);
}

export function isALaCarteAppointment(name: string) {
   return name.match(/a-la-carte/);
}

export function shortenText(str: string, maxLen: number, separator = '…') {
   return str && str.length > maxLen
      ? str.slice(0, maxLen).split(' ').slice(0, -1).join(' ') + separator
      : str;
}

export function shortenCharacters(
   str: string,
   maxLen: number,
   separator = '…',
) {
   return str && str.length > maxLen ? str.slice(0, maxLen) + separator : str;
}

const CART_ITEM_NAME_MAX_LENGTH = 21;

export function shortenCartItemName(
   name: string,
   length = CART_ITEM_NAME_MAX_LENGTH,
) {
   const shortenedName = name.replace('Session', '');

   if (shortenedName.length <= length) {
      return shortenedName;
   }

   return shortenText(shortenedName, length);
}

const TIMETAP_DATE_FORMAT = 'yyyy-MM-dd';

export function parseTimetapDate(date: string) {
   return DateTime.fromFormat(date, TIMETAP_DATE_FORMAT, {
      zone: 'UTC',
   });
}

export function formatTimetapDate(date: Date | DateTime) {
   return (date instanceof Date ? DateTime.fromJSDate(date) : date).toFormat(
      TIMETAP_DATE_FORMAT,
   );
}

export function replaceOrAddToArray<T>(
   arr: T[],
   item: T,
   cmp: (a: T) => boolean,
) {
   let found = false;

   const newArr: T[] = [];
   for (const el of arr) {
      if (cmp(el)) {
         found = true;
         newArr.push(item);
      } else {
         newArr.push(el);
      }
   }

   if (!found) {
      return [...newArr, item];
   }

   return newArr;
}

export function guessUserTimezone() {
   try {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
   } catch (edit) {
      return 'US/Eastern';
   }
}

export function getTimeslot(slot: TimetapTimeSlot): Timeslot {
   const {
      staffStartDateTimeUTC,
      staffEndDateTimeUTC,
      clientStartDate,
      clientStartTime,
      clientEndDate,
      clientEndTime,
      staffStartDate,
      staffStartTime,
      staffEndDate,
      staffEndTime,
   } = slot;

   return {
      date: staffStartDate,
      startTime: DateTime.fromMillis(staffStartDateTimeUTC, {
         zone: 'UTC',
      }).toISO()!,
      endTime: DateTime.fromMillis(staffEndDateTimeUTC, {
         zone: 'UTC',
      }).toISO()!,

      staff: {
         startDate: staffStartDate,
         startTime: staffStartTime,
         endDate: staffEndDate,
         endTime: staffEndTime,
         startDateUtc: staffStartDateTimeUTC,
         endDateUtc: staffEndDateTimeUTC,
      },
      client: {
         startDate: clientStartDate,
         startTime: clientStartTime,
         endDate: clientEndDate,
         endTime: clientEndTime,
      },
   };
}

export function isGlobalVirtualLocation(location: TimetapLocationResponseDto) {
   return (
      location.locationType === TimetapLocationType.Virtual &&
      location.locationName === 'Virtual'
   );
}

export function getStringifiedUrlParams(params?: {
   [Key: string]:
      | string
      | string[]
      | number
      | number[]
      | boolean
      | null
      | undefined;
}): Record<string, string> {
   const convertToString = (
      item: string | number | boolean | null | undefined,
   ) => {
      if (_.isString(item)) {
         return item;
      }
      if (_.isNumber(item)) {
         return item.toString();
      }
      if (_.isBoolean(item)) {
         return item.toString();
      }

      return undefined;
   };
   return _.entries(params ?? {}).reduce<Record<string, string>>(
      (acc, [key, value]) => {
         const s = _.isArray(value)
            ? value
                 .map(convertToString)
                 .filter((v) => _.isString(v))
                 .join(',')
            : convertToString(value);

         if (_.isString(s)) {
            return {
               ...acc,
               [key]: s,
            };
         }
         return acc;
      },
      {},
   );
}

export const getErrorMessage = (error: unknown): string => {
   if (typeof error === 'string') {
      return error;
   }

   if (axios.isAxiosError<unknown>(error)) {
      if (_.isObject(error.response?.data)) {
         const data = error.response.data;

         // ClaimMD
         if (isClaimMdExceptionPayload(data)) {
            const errors = data.errors;
            return errors?.[0].error_mesg ?? 'Unknown ClaimMD error';
         }

         if ('message' in data && _.isString(data.message)) {
            return data.message;
         }
      }
      return error.message;
   }

   if (error instanceof Error) {
      return error.message;
   }

   // Example: we're returning transactionError in payment error response. This is not an instance of
   // Error, but just a regular object.
   if (_.isObject(error) && 'message' in error && _.isString(error.message)) {
      return error.message;
   }

   return 'An unknown error occurred';
};

export const getCookieSession = <T>(cookiePrefix: string): T | null => {
   const cookies = parseCookies(document.cookie);
   const cookieKey = `${cookiePrefix}id_token_public`;
   try {
      if (cookies[cookieKey]) {
         return JSON.parse(
            Buffer.from(cookies[cookieKey], 'base64').toString(),
         ) as T;
      }
   } catch (err) {
      // noop, just returning null
   }
   return null;
};

export const parseCookies = (cookie: string): Record<string, string> => {
   return cookie.split(';').reduce((acc, cookie) => {
      const [key, value] = cookie.trim().split('=');
      return { ...acc, [key]: value };
   }, {});
};

export const formatCoPay = (amount: number | null | undefined) => {
   if (_.isNumber(amount) && amount > 0) {
      return `$${amount}`;
   }

   return `$25-$75`;
};

export async function sleep(ms: number) {
   return new Promise((resolve) => setTimeout(resolve, ms));
}

export function getFormsortIntakeProgress(
   allAnswers: FormsortAnswersResponse[],
) {
   const MedicalIntakeForms = [
      FormsortFormTypes.MedicalIntakeProfile,
      FormsortFormTypes.MedicalIntakeMedicalHistory,
      FormsortFormTypes.MedicalIntakeMentalHealth,
      FormsortFormTypes.MedicalIntakePsychiatricHistory,
      FormsortFormTypes.MedicalIntakePsychedelicExperiences,
   ];

   const allMIForms = allAnswers.filter((form) =>
      MedicalIntakeForms.includes(form.formsort_form_type as FormsortFormTypes),
   );

   // sort the forms so we set all forms until the last one as finalized
   const sortedMIForms: {
      finalized: boolean;
      formsort_form_type: FormsortFormTypes;
   }[] = [];
   Object.values(MedicalIntakeForms).forEach((form) => {
      const dbForm = allMIForms.find((f) => f.formsort_form_type === form);

      if (dbForm) {
         sortedMIForms.push({
            ...dbForm,
            formsort_form_type: dbForm.formsort_form_type as FormsortFormTypes,
         });
      }
   });

   const completedForms = sortedMIForms.filter((form) => form.finalized);

   const percentageCompleted = (
      (completedForms.length / MedicalIntakeForms.length) *
      100
   ).toFixed();

   return {
      forms: allMIForms,
      percentageCompleted,
   };
}

// Silently swallows any errors and returns undefined if string is NaN
export function tryParseFloat(str: string): number | undefined {
   const res = parseFloat(str);
   if (Number.isNaN(res)) {
      return;
   }

   return res;
}

/**
 * Automations in Welkin consider encounter completed if it has disposition code set to "Appointment completed".
 * Whole app considers encounter completed if it has status set to "Completed".
 *
 * This is something we'll have to figure out. For now, using this function for determining if encounter is completed
 * will be useful.
 *
 * @param param0
 * @returns
 */
export function isEncounterCompleted({
   status,
}: {
   status: WelkinEncounterStatus;
   dispositionCode: EncounterDispositionCodes | null;
}) {
   return status === WelkinEncounterStatus.Completed;
}

const WEEKS_IN_MONTH = 4.3452381;

export function getWeeksFromMonths(months: number) {
   return Math.round(months * WEEKS_IN_MONTH);
}

export function getWeeksFromPaymentCode(code: PaymentCode) {
   // months
   if (
      [
         PaymentCode.FourMonths,
         PaymentCode.Monthly,
         PaymentCode.TwoMonths,
      ].includes(code)
   ) {
      return getWeeksFromMonths(getNumberOfPaymentsForPaymentCode(code));
   }

   // weeks
   if (code === PaymentCode.Weekly) {
      return getNumberOfPaymentsForPaymentCode(code);
   }

   return null; // not applicable for payment method
}
