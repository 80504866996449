import {
   LevelResultDto,
   MoodHistoryDto,
   MoodTrackingFirstWeeklyPauseDto,
   MoodTrackingPaginatedDto,
   ProgressDto,
   WeeklyPauseInfoDto,
   cursorPaginationQueryParamsSchema,
   levelsParamsSchema,
   rangeParamSchema,
   updateMoodTrackingDtoSchema,
} from '@innerwell/dtos';
import { MoodTracking } from '@innerwell/prisma-app';
import { initContract } from '@ts-rest/core';

const c = initContract();
export const moodTracking = c.router(
   {
      progress: {
         method: 'GET',
         path: '/mood-tracking/progress',
         query: rangeParamSchema,
         responses: {
            200: c.type<ProgressDto>(),
         },
      },
      weeklyPauseRequiredFlows: {
         method: 'GET',
         path: '/mood-tracking/weekly-pause-required-flows',
         responses: {
            200: c.type<WeeklyPauseInfoDto>(),
         },
      },
      getMoodTracking: {
         method: 'GET',
         path: '/mood-tracking',
         query: cursorPaginationQueryParamsSchema,
         responses: {
            200: c.type<MoodTrackingPaginatedDto>(),
         },
      },

      createMoodTracking: {
         method: 'POST',
         path: '/mood-tracking',
         body: updateMoodTrackingDtoSchema,
         responses: {
            200: c.type<MoodTracking>(),
         },
      },

      weeklyPauseRequired: {
         method: 'GET',
         path: '/mood-tracking/weekly-pause-required',
         responses: {
            200: c.type<boolean>(),
         },
      },

      getFirstWeeklyPause: {
         method: 'GET',
         path: '/mood-tracking/first-weekly-pause',
         responses: {
            200: c.type<MoodTrackingFirstWeeklyPauseDto | null>(),
         },
      },

      getAnxietyLevels: {
         method: 'GET',
         path: '/mood-tracking/anxiety-levels',
         query: levelsParamsSchema,
         responses: {
            200: c.type<LevelResultDto[]>(),
         },
      },

      getDepressionLevels: {
         method: 'GET',
         path: '/mood-tracking/depression-levels',
         query: levelsParamsSchema,
         responses: {
            200: c.type<LevelResultDto[]>(),
         },
      },

      getPcl5Levels: {
         method: 'GET',
         path: '/mood-tracking/pcl5-levels',
         query: levelsParamsSchema,
         responses: {
            200: c.type<LevelResultDto[]>(),
         },
      },

      getHistory: {
         method: 'GET',
         path: '/mood-tracking/history',
         query: rangeParamSchema,
         responses: {
            200: c.type<MoodHistoryDto>(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
