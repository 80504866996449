import { initContract } from '@ts-rest/core';
import { Playlist, Song } from '@innerwell/dtos';
import { z } from 'zod';

const c = initContract();
export const dosingPlaylists = c.router(
   {
      getAll: {
         method: 'GET',
         path: '/dosing-playlists/all',
         responses: {
            200: c.type<Playlist[]>(),
         },
      },
      getById: {
         method: 'GET',
         path: '/dosing-playlists/:playlistId',
         pathParams: z.object({
            playlistId: z.string(),
         }),
         responses: {
            200: c.type<Playlist | null>(),
         },
      },
      getSongs: {
         method: 'GET',
         path: '/dosing-playlists/:playlistId/songs',
         pathParams: z.object({
            playlistId: z.string(),
         }),
         responses: {
            200: c.type<Song[]>(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
