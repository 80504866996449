import { initContract } from '@ts-rest/core';
import {
   facebookBaseParamsSchema,
   FacebookConversionResponse,
} from '@innerwell/dtos';

const c = initContract();
export const facebookConversion = c.router(
   {
      track: {
         method: 'POST',
         path: '/facebook-conversion/track',
         body: facebookBaseParamsSchema,
         responses: {
            200: c.type<FacebookConversionResponse | void>(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
