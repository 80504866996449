import { initContract } from '@ts-rest/core';
import {
   InnerwellProduct,
   InnerwellProductCategory,
   InnerwellProductVariant,
   ProductPlansResponseDto,
   addonsQueryDtoSchema,
   intExtendedField,
} from '@innerwell/dtos';
import { z } from 'zod';

const c = initContract();
export const products = c.router(
   {
      plans: {
         method: 'GET',
         path: '/products/plans',
         responses: {
            200: c.type<ProductPlansResponseDto>(),
         },
         metadata: {
            dontCheckCredentials: true,
         },
      },
      byCategory: {
         method: 'GET',
         path: '/products/by-category/:categoryId',
         pathParams: z.object({
            categoryId: intExtendedField,
         }),
         responses: {
            200: c.type<InnerwellProduct[]>(),
         },
         metadata: {
            dontCheckCredentials: true,
         },
      },
      bySkus: {
         method: 'GET',
         path: '/products/by-skus/:skus',
         pathParams: z.object({
            skus: z.string(),
         }),
         responses: {
            200: c.type<InnerwellProductVariant[]>(),
         },
         metadata: {
            dontCheckCredentials: true,
         },
      },
      getCategoryById: {
         method: 'GET',
         path: '/products/category/:categoryId',
         pathParams: z.object({
            categoryId: intExtendedField,
         }),
         responses: {
            200: c.type<InnerwellProductCategory>(),
         },
         metadata: {
            dontCheckCredentials: true,
         },
      },
      addOns: {
         method: 'GET',
         path: '/products/addOns',
         query: addonsQueryDtoSchema,
         responses: {
            200: c.type<InnerwellProduct[]>(),
         },
      },
      medicalAddons: {
         method: 'GET',
         path: '/products/medicalAddons',
         responses: {
            200: c.type<InnerwellProduct[]>(),
         },
      },
      followOns: {
         method: 'GET',
         path: '/products/followOns',
         responses: {
            200: c.type<InnerwellProduct[]>(),
         },
      },
      missedAppointmentFee: {
         method: 'GET',
         path: '/products/missed-appointment-fee',
         responses: {
            200: c.type<InnerwellProduct>(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
