import { AddonPurchasesWithoutCount } from '@innerwell/dtos';
import { initContract } from '@ts-rest/core';

const c = initContract();
export const medicalAddons = c.router(
   {
      getAvailableAddons: {
         method: 'GET',
         path: '/medical-addons/available',
         responses: {
            200: c.type<AddonPurchasesWithoutCount[]>(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
