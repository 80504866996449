import {
   TimetapAppointmentResponseDto,
   TimetapAppointmentType,
   TimetapCalendarRangeEventDto,
   TimetapClientResponseDto,
   TimetapLocationAvailabilityResultDto,
   TimetapLocationResponseDto,
   TimetapServiceResponseDto,
   TimetapStaffAvailabilityForDateResultDto,
   TimetapStaffMonthlyAvailabilityResultDto,
   TimetapStaffResponseDto,
   TimetapTimeSlot,
   clientsFilterDtoSchema,
   intExtendedField,
   timetapCalendarParamsSchema,
   timetapCancelAppointmentParamsSchema,
   timetapCreateAppointmentParamsSchema,
   timetapDailyStaffAvailabilityParamsSchema,
   timetapLocationAvailabilityParamsSchema,
   timetapLocationsParamsSchema,
   timetapLocationsReportParamsSchema,
   timetapMonthlyStaffAvailabilityParamsSchema,
   timetapServiceParamsSchema,
   timetapStaffAvailabilityForDateParamsSchema,
   timetapStaffParamsSchema,
} from '@innerwell/dtos';
import { initContract } from '@ts-rest/core';
import { z } from 'zod';

const c = initContract();
export const timetap = c.router(
   {
      getLocations: {
         method: 'GET',
         path: '/timetap/locations',
         query: timetapLocationsParamsSchema,
         responses: {
            200: c.type<TimetapLocationResponseDto[]>(),
         },
      },
      getLocationsReport: {
         method: 'GET',
         path: '/timetap/locationsReport',
         query: timetapLocationsReportParamsSchema,
         responses: {
            200: c.type<TimetapLocationResponseDto[]>(),
         },
      },

      getStaff: {
         method: 'GET',
         path: '/timetap/staff',
         query: timetapStaffParamsSchema,
         responses: {
            200: c.type<TimetapStaffResponseDto[]>(),
         },
      },

      getServices: {
         method: 'GET',
         path: '/timetap/services',
         query: timetapServiceParamsSchema,
         responses: {
            200: c.type<TimetapServiceResponseDto[]>(),
         },
      },
      getServiceById: {
         method: 'GET',
         path: '/timetap/service/by-name/:name',
         pathParams: z.object({
            name: z.string(),
         }),
         responses: {
            200: c.type<TimetapServiceResponseDto | null>(),
         },
      },

      getEvents: {
         method: 'GET',
         path: '/timetap/events',
         query: timetapCalendarParamsSchema,
         responses: {
            200: c.type<TimetapCalendarRangeEventDto[]>(),
         },
      },

      createAppointment: {
         method: 'POST',
         path: '/timetap/appointments',
         body: timetapCreateAppointmentParamsSchema,
         responses: {
            200: c.type<TimetapAppointmentResponseDto>(),
         },
      },
      cancelAppointment: {
         method: 'POST',
         path: '/timetap/appointments/:calendarId/cancel',
         pathParams: z.object({
            calendarId: intExtendedField,
         }),
         body: timetapCancelAppointmentParamsSchema,
         responses: {
            200: c.noBody(),
         },
      },
      getAppointmentByEventTypeAndId: {
         method: 'GET',
         path: '/timetap/appointments/:eventType/:calendarId',
         pathParams: z.object({
            eventType: z.nativeEnum(TimetapAppointmentType),
            calendarId: intExtendedField,
         }),
         responses: {
            200: c.type<TimetapCalendarRangeEventDto | null>(),
         },
      },
      getAppointmentById: {
         method: 'GET',
         path: '/timetap/appointments/:calendarId',
         pathParams: z.object({
            calendarId: intExtendedField,
         }),
         responses: {
            200: c.type<TimetapAppointmentResponseDto | null>(),
         },
      },

      getLocationAvailability: {
         method: 'GET',
         path: '/timetap/availability/location',
         query: timetapLocationAvailabilityParamsSchema,
         responses: {
            200: c.type<TimetapLocationAvailabilityResultDto[]>(),
         },
      },

      getStaffAvailabilityDaily: {
         method: 'GET',
         path: '/timetap/availability/staff/daily',
         query: timetapDailyStaffAvailabilityParamsSchema,
         responses: {
            200: c.type<TimetapTimeSlot[]>(),
         },
      },

      getStaffAvailabilityMonthly: {
         method: 'GET',
         path: '/timetap/availability/staff/monthly',
         query: timetapMonthlyStaffAvailabilityParamsSchema,
         responses: {
            200: c.type<TimetapStaffMonthlyAvailabilityResultDto>(),
         },
      },
      getStaffAvailableForDate: {
         method: 'GET',
         path: '/timetap/availability/staff-for-date',
         query: timetapStaffAvailabilityForDateParamsSchema,
         responses: {
            200: c.type<TimetapStaffAvailabilityForDateResultDto[]>(),
         },
      },

      getClients: {
         method: 'POST',
         path: '/timetap/clients/filter',
         body: clientsFilterDtoSchema,
         responses: {
            200: c.type<TimetapClientResponseDto[]>(),
         },
      },
      getClientById: {
         method: 'GET',
         path: '/timetap/clients/:clientId',
         pathParams: z.object({
            clientId: intExtendedField,
         }),
         responses: {
            200: c.type<TimetapClientResponseDto>(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
