import { createInnerwellApiClient } from '@innerwell/api-client';
import {
   account,
   adsCounter,
   appointmentBank,
   appointments,
   auth,
   blog,
   cart,
   chat,
   contactConsent,
   customerio,
   dosingPlaylists,
   facebookConversion,
   followons,
   formsort,
   insurance,
   medicalAddons,
   missedAppointments,
   moodTracking,
   patients,
   payments,
   products,
   programs,
   programSwitch,
   scheduling,
   timetap,
   users,
   webhook,
   welkin,
   zoom,
} from '@innerwell/contracts';
import { getCookieSession } from '@innerwell/utils';
import { isAxiosError } from 'axios';

import { getClientPublicRuntimeConfig } from '@/services/env/utils/client-public-runtime-config';

/**
 * New api client
 */
const routerContracts = {
   appointmentBank,
   account,
   adsCounter,
   appointments,
   auth,
   blog,
   cart,
   chat,
   contactConsent,
   customerio,
   dosingPlaylists,
   facebookConversion,
   followons,
   formsort,
   insurance,
   medicalAddons,
   missedAppointments,
   moodTracking,
   patients,
   payments,
   products,
   programSwitch,
   programs,
   scheduling,
   timetap,
   users,
   webhook,
   welkin,
   zoom,
};

const REFRESH_PATH = '/auth/login';

export const webApiClient = createInnerwellApiClient(
   {
      baseURL: getClientPublicRuntimeConfig().apiUrl,
      useRefreshTokenInterceptors: true,
      onRefreshTokenError(error) {
         if (
            isAxiosError(error) &&
            error.response &&
            window.location.pathname !== REFRESH_PATH &&
            [401, 403].includes(error.response.status)
         ) {
            window.location.href = `${REFRESH_PATH}?${new URLSearchParams({
               error: 'RefreshTokenError',
               callbackUrl: window.location.href,
            })}`;
         }
      },
      getCookieExp() {
         return getCookieSession<{ exp: number }>('')?.exp;
      },
      withCredentials: true,
   },
   routerContracts,
);
