import { initContract } from '@ts-rest/core';
import {
   adminCognitoLoginSchema,
   changePasswordDtoSchema,
   cognitoLoginSchema,
   confirmForgotPasswordSchemaDto,
   forgotPasswordDtoSchema,
   resendCodeSchema,
   sessionInfo,
   signupSchema,
   verifyUserAttributeSchema,
} from '@innerwell/dtos';
import {
   ChangePasswordCommandOutput,
   ConfirmForgotPasswordCommandOutput,
   ForgotPasswordCommandOutput,
   GetUserAttributeVerificationCodeCommandOutput,
   VerifyUserAttributeCommandOutput,
} from '@aws-sdk/client-cognito-identity-provider';

const c = initContract();
export const auth = c.router(
   {
      login: {
         method: 'POST',
         path: '/auth/login',
         body: cognitoLoginSchema,
         responses: {
            200: c.type<void>(),
         },
         summary: 'Login endpoint',
         description: 'Returns authentication tokens as cookies.',
         metadata: {
            //example
            //openApiTags: ['aaa', 'bbb', 'ccc'],
            dontCheckCredentials: true,
         },
      },

      adminLogin: {
         method: 'POST',
         path: '/auth/admin-login',
         body: adminCognitoLoginSchema,
         responses: {
            200: c.type<void>(),
         },
         metadata: {
            dontCheckCredentials: true,
         },
      },

      getSessionInfo: {
         method: 'GET',
         path: '/auth/session-info',
         responses: {
            200: sessionInfo,
         },
      },

      refreshToken: {
         method: 'POST',
         path: '/auth/refresh',
         body: c.noBody(),
         responses: {
            200: c.type<void>(),
         },
      },

      signup: {
         method: 'POST',
         path: '/auth/signup',
         body: signupSchema,
         responses: {
            200: c.type<void>(),
         },
         metadata: {
            dontCheckCredentials: true,
         },
      },

      resendVerificationCode: {
         method: 'POST',
         path: '/auth/resend-verification-code',
         body: resendCodeSchema,
         responses: {
            200: c.type<GetUserAttributeVerificationCodeCommandOutput>(),
         },
      },

      verifyUserAttribute: {
         method: 'POST',
         path: '/auth/verify-user-attribute',
         body: verifyUserAttributeSchema,
         responses: {
            200: c.type<VerifyUserAttributeCommandOutput>(),
         },
      },

      setNewPassword: {
         method: 'POST',
         path: '/auth/change-password',
         body: changePasswordDtoSchema,
         responses: {
            200: c.type<ChangePasswordCommandOutput>(),
         },
      },

      forgotPassword: {
         method: 'POST',
         path: '/auth/forgot-password',
         body: forgotPasswordDtoSchema,
         responses: {
            200: c.type<ForgotPasswordCommandOutput>(),
         },
         metadata: {
            dontCheckCredentials: true,
         },
      },

      confirmForgotPassword: {
         method: 'POST',
         path: '/auth/confirm-forgot-password',
         body: confirmForgotPasswordSchemaDto,
         responses: {
            200: c.type<ConfirmForgotPasswordCommandOutput>(),
         },
         metadata: {
            dontCheckCredentials: true,
         },
      },

      logout: {
         method: 'POST',
         path: '/auth/logout',
         body: c.noBody(),
         responses: {
            200: c.type<void>(),
         },
         metadata: {
            dontCheckCredentials: true,
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
