import {
   EligibilityResponseDataDto,
   InsuranceAvailableEncountersResponseDto,
   PayerResponseDto,
   availableEncountersParamsSchema,
   getEligibilityIntakeSchema,
   getEligibilitySchema,
   getPayersSchema,
} from '@innerwell/dtos';
import { initContract } from '@ts-rest/core';

const c = initContract();
export const insurance = c.router(
   {
      getPayers: {
         method: 'GET',
         path: '/insurance/payers',
         query: getPayersSchema,
         responses: {
            200: c.type<PayerResponseDto[]>(),
         },
         metadata: {
            dontCheckCredentials: true,
         },
      },
      getEligibility: {
         method: 'POST',
         path: '/insurance/eligibility',
         body: getEligibilitySchema,
         responses: {
            200: c.type<EligibilityResponseDataDto>(),
         },
         metadata: {
            dontCheckCredentials: true,
         },
      },
      getEligibilityInIntake: {
         method: 'POST',
         path: '/insurance/eligibility/intake',
         body: getEligibilityIntakeSchema,
         responses: {
            200: c.type<EligibilityResponseDataDto>(),
         },
      },

      getAvailableEncounters: {
         method: 'GET',
         path: '/insurance/available-encounters',
         query: availableEncountersParamsSchema,
         responses: {
            200: c.type<InsuranceAvailableEncountersResponseDto>(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
